@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
* {
  box-sizing: border-box;
}


/*
html::-webkit-scrollbar {
  display: none;
}
*/

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #f5f5f5;
/* background: rgb(231,236,243);
background: linear-gradient(6deg, rgba(231,236,243,1) 0%, rgba(233,238,244,1) 35%, rgba(248,249,251,1) 100%); */
 margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  display: block;
  color: #000000;
}

button {
  outline: none;
  border: none;
}

.listingbackground {
  background-color: #f5f5f5;
}

.maintag {
  font-size: 18px;
  margin-top: -20px;
  margin-bottom: 20px;
  color: #6a6a6a;
}

.padbot {
  margin-bottom: 100px;
}
.dis1 {
  font-size: 14px !important;
  font-weight: 200;
}

.star {
  position: relative;
  margin-left: 145px;
  margin-top: -10px;
}


.logonori {
  height: auto;
  width: 200px;
  position: absolute;
  top: 50px;
  left: 50px;
}

.input,
.passwordInput,
.emailInput,
.nameInput,
.textarea
 {
  box-shadow: rgba(138, 7, 0, 0.11);
  border: none;
  background: #ffffff;
  border-radius: 3rem;
  height: 3rem;
  width: 100%;
  outline: none;
  font-family: 'Montserrat', sans-serif;
  padding: 0 3rem;
  font-size: 1rem;
}




.swiper-button-prev {
  background-color: rgb(255, 255, 255);

}

.swiper-button-next {
  background-color: rgb(255, 255, 255);
}

@media (min-width: 1100px) {
  .input,
  .passwordInput,
  .emailInput,
  .nameInput,
  .textarea {
    padding: 0 5rem;
  }




  .formSelect {
  width: 98%;
  max-width: 1366px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


}


.falaspremiumContainer {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: space-evenly
}

.falas {
  min-width: 100px;
  width: 350px;
  height: 700px;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
}

.kontaktContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 0;
  left: 0;
  width: 98%;
  justify-content: space-evenly;
  border-radius: 15px;
  margin-bottom: 50px;
}



.premium {
  width: 350px;
  min-width: 100px;
  height: 700px;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
}

.bazike {
  width: 350px;
    min-width: 100px;
  height: 700px;
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
}

.telefoni {
  /* background-color: #fff; */
  border-radius: 15px;
  padding: 15px;
  font-size: 28px;
  color: #ffffff;
}

.adresa {
  width: 500px;
  /* background-color: #fff; */
  border-radius: 15px;
  padding: 15px;
}


.cmimiPremium {
  font-size: 45px;
  color: #ff8a2a;
  margin-right: 10px;
}

.cmimiMuaji {
  color: #b2b2b2;
  font-size: 16px;
}

.phoneCont {
  display: flex;
  flex-direction: row;
  align-items: center;

} 


.kontaktiContainer {
  padding: 15px;
  background-color: #fff;
  margin-top: 10px;
}

.kontaktContainer {
  padding: 0px;
  background-color: #ff8a2a;
  margin-top: -40px;
  color: #fff;
}


.telefoniText {
  font-size: 18px;
}

.textarea {
  padding: 1rem 1.5rem;
  height: 300px;
  border-radius: 1rem;
}

.primaryButton {
  cursor: pointer;
  background: #ff8a2a;
  border-radius: 1rem;
  padding: 0.85rem 2rem;
  color: #383838;
  font-weight: 600;
  font-size: 1.25rem;
  width: 80%;
  margin: 0uto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  max-width: 1366px;
}

.editButtons {
  position: absolute;
  padding-top: 130px;
  margin-left: -15px;
}

.removeIcon {
  background-color: #959595;
  padding: 0px;
  border-radius:  0 15px 15px 0;
  margin-top:40px;
  width: 84px;
  height: 40px;
  left: 305px;
  cursor: pointer;
  position: absolute;
  top: 3%;
  right: 2%;
}

.editIcon {
  background-color: #959595;
  padding: 0px;
  border-radius:  0 15px 15px 0;
  margin-top:-7px;
  width: 84px;
  height: 40px;
  left: 305px;
  cursor: pointer;
  position: absolute;
  top: 3.4%;
  right: 30px;
}

.premiumIcon {
  background-color: #959595;
  padding: 0px;
  border-radius:  0 15px 15px 0;
  margin-top:87px;
  width: 84px;
  height: 40px;
  left: 305px;
  cursor: pointer;
  position: absolute;
  top: 3.4%;
  right: 30px;
}

.editIcon p,
.removeIcon p, 
.premiumIcon p {
  margin-top: 10px;
   padding-left: 6px;
   color: #ffffff;
   font-size: 14px;
   font-weight: 500;
}


.pageContainer,
.offers,
.profile,
.listingDetails,
.category,
.explore {
  margin: 1rem;
}



.dis {
  font-size: 13px;
  padding-bottom:0px;
}

.qendra {
  text-align: center;
  margin-top: 30px;

}

.distop {
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 0px;
}

.dis {
  font-size: 13px;
  padding-bottom: 100px;
}
@media (min-width: 1024px) {
  .pageContainer,
  .offers,
  .profile,
  .listingDetails,
  .category,
  .explore {
    margin: 3rem;
  }


    .pageContainer {
    width:100%;
    display: flex;
    flex-direction: row;
    height: 500px;
  }

}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border-width: 8px; /* Set the border width */
  border-style: solid; /* Set the border style to solid */
  border-radius: 50%; /* Make it a circle */
  border-color: #ff8a2a #ff8a2a #ff8a2a transparent; /* Set the border colors */
  animation: spin 1.2s linear infinite; /* Apply animation */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.splitDiv {
  display: flex;
  flex-direction: row;
}

header {
  margin-top: 100px;
}

.pageHeader {
  font-size: 2rem;
  font-weight: 800;
  padding-left: 50px;
  margin-top: -80px;
  padding-right: 150px;
}


.jobclass2 {
  margin-left: 0px;
  margin-top: 50px;
}

.usersListAdmin {
  font-size: 16px;
  background-color: #ffffff;
  color: #f5f5f5;
  padding: 12px;
  width: 300px;
  margin-bottom: 20px;
  border: 1px solid #ff9e6d;
  border-radius: 15px;
}

.usersNameAdmin {
  font-size: 16px;
  font-weight: 600;
}

.cmimi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.navbar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 85px;
  background-color: #888888;
    /* background-color: #ff8a2a; */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.briefcase {
  height: 36px;
  color: #fcef00;
}

.navbarNav {
  width: 100%;
  margin-top: 0.75rem;
  overflow-y: hidden;
}

.navbarListItems {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.navbarListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbarListItemName,
.navbarListItemNameActive {
  margin-top: 0.25rem;
  font-size: 14px;
  font-weight: 600;
  color: #ededed;
}

.navbarListItemNameActive {
  color: #ededed;
}

.padingu {
  padding-bottom: 20px;
}

.splitDivContent {
  padding-top: 8px;
}

.nameInput {
  margin-bottom: 2rem;
  background: url('./assets/svg/badgeIcon.svg') #ffffff 2.5% center no-repeat;
}

.emailInput {
  margin-bottom: 2rem;
  background: url('./assets/svg/personIcon.svg') #ffffff 2.5% center no-repeat;
}

.passwordInputDiv {
  position: relative;
}

.passwordInput {
  margin-bottom: 2rem;
  background: url('./assets/svg/lockIcon.svg') #ffffff 2.5% center no-repeat;
}

.showPassword {
  cursor: pointer;
  position: absolute;
  top: -4%;
  right: 1%;
  padding: 1rem;
}

.forgotPasswordLink {
  cursor: pointer;
  color: #000000;
  font-weight: 600;
  text-align: right;
}

.signInBar,
.signUpBar {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: inherit;
}

.signInButton,
.signUpButton,
.signInText,
.signUpText {
  cursor: pointer;
}

.bottomSpace {
  padding-bottom: 70px;
}

@media (min-width: 1024px) {
  .signInBar,
  .signUpBar {
    justify-content: start;
  }




}

.signInText,
.signUpText {
  font-size: 1.5rem;
  font-weight: 700;
}

.signInButton,
.signUpButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background-color: #ff8a2a;
  border-radius: 50%;
  color:#ffffff;
}
@media (min-width: 1024px) {
  .signInButton,
  .signUpButton {
    margin-left: 3rem;
  }

  .categoryListings {
    margin-top: 50px;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto; 
    gap: 15px 10px;
  }

}

.socialLogin {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin: 1.5rem;
  width: 3rem;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.socialIconImg {
  width: 100%;
}

.registerLink {
  margin-top: 1rem;
  padding: 20px 20px 20px 20px;
  color: rgb(244, 244, 244);
  background-color: #888888;
  font-weight: 600;
  text-align: center;
  margin-bottom: 200px;
  border-radius: 15px;
}

.registerLink2 {
  cursor: pointer;
  margin-top: 50px;
  font-size: 17px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  color: rgb(252, 252, 252);
  background-color: #ff8a2a;
  font-weight: 600;
  text-align: center;
  border-radius: 15px;
}


.edit-listing-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 80px;
}

.input-container {
  margin-bottom: 15px;
}

label {
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 20px;
}

input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.5); /* Make the checkbox larger */
}

.save-button {
  background-color: #ff6200;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;
}

.back-link {
  margin-top: 10px;
  display: inline-block;
  color: #ff6200;
  text-decoration: none;
}


.dashboard-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
  color: #ff7f00; /* Orange color for header */
}

.user-list {
  list-style: none;
  padding: 0;
}

.user-item {
  background-color: #e5e5e5; /* Light orange background color */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #e2e2e2; /* Border color */
}

.user-link {
  text-decoration: none;
  color: #333;
}

.user-name {
  font-weight: bold;
  color: #333333; /* Orange color for name */
}

.user-email {
  color: #555555;
}


.search-user-form {
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff; /* Change border color when focused */
}


.user-listings-page {
  max-width: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa; /* Light gray background color */
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
}

.admin-link {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom:10px;
  text-decoration: none;
  color: #ffffff;
  border-radius: 5px;
  background-color: #a7a7a7;
}



@media (min-width: 1217px) {
  .explore {
    margin-bottom: 10rem;
  }

.searchinput {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}


  .categoryListings {
    margin-top: 50px;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto ; 
    gap: 35px 10px;
  }

}
@media (max-height: 536) {
  .explore {
    margin-bottom: 10rem;
  }

}
  
.exploreHeading {
  color: #383838;
  font-weight: 700;
}

.exploreCategoryHeading {
  font-weight: 700;
}

.exploreCategoryHeading {
  margin-top: 3rem;
}

.slider-homepage {
  height: 23vw ;
}

.swiper-container {
  height: 125px;
  width:100%;
}

.rightside {
  width: 33%;
  height: auto;
}

.leftside {
  width: 33%;
  height: auto;
}

.topsidea {
  width: 20%;
  height: auto;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #dddddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.swiperSlideDiv {
  border-radius: 15px !important;
}

.topsideb {
  width: 20%;
  height: auto;
}

.topsidec {
  width: 20%;
  height: auto;
}

.topsided {
  width: 20%;
  height: auto;
}




.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.swiperSlideDiv {
  position: relative;
  width: 100%;
  height: 100px;
}

.swiperSlideImg {
  width: 100%;
  object-fit: cover;
}

.swiperSlideText {
  color: #ffffff;
  position: absolute;
  top: 70px;
  left: 0;
  font-weight: 600;
  max-width: 90%;
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
}
@media (min-width: 1024px) {
  .swiperSlideText {
    font-size: 1.75rem;
  }
}

.swiperSlidePrice {
  color: #000000;
  position: absolute;
  top: 143px;
  left: 11px;
  font-weight: 600;
  max-width: 90%;
  background-color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
}
@media (min-width: 1024px) {
  .swiperSlidePrice {
    font-size: 1.25rem;
  }


}

.exploreCategories {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}

.topside {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}

.exploreCategories a {
  width: 48%;
}



.exploreCategoryImg {
  min-height: 115px;
  height: 15vw;
  width: 100%;
  border-radius: 1.5rem;
  object-fit: cover;
  margin: 0 auto;
}

.exploreCategoryName {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.category {
  margin-bottom: 10rem;
}


.categoryListing {
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  width: 300px;
  height:165px;
  padding-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.userMargin {
    margin-left: -37px;
}

.listingText {
  margin-left: 37px;
}

.categoryListing2 {
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  width: 300px;
  height:130px;
  padding-left: 10px;
box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.categoryListingLink {
  display: contents;
}


.image-wrapper2 {
  display: flex;
  align-items: center;
}

.image-container {
  margin-bottom: 100px;
}

.categoryListingImg {
  width: 100px;
  height: 100px;
  border-radius: 0.5rem;
  object-fit: cover;
  margin-right: 15px;
}

.positionicon {
  display: flex;
  align-items: center;
  color: #343434;
font-family: "Montserrat", sans-serif;
}

.maindetyrat {
  color: #ff8a2a;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 500;
}

.mainpozita {
  color: #323232;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 600;
}

.dataeskadimit {
  background-color: #ff8a2a;
  color: #ffffff;
  padding: 7px;
  border-radius: 10px;
}

.toplisting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconMargin {
  margin-right: 10px;
  filter: opacity(0.3);
}

@media (min-width: 1024px) {
  .categoryListingImg {
    width: 100px;
    height: 100px;
  }
  
}

.categoryListingDetails {
  width: 65%;
  padding-left: 10px;
}
@media (min-width: 1024px) {
  .categoryListingDetails {
    width: 79%;
  }
}

.categoryListingLocation {
  font-weight: 600;
  font-size: 14px;
  opacity: 0.8;
  margin-bottom: 0;
  color: #a7a7a7;
}


.categoryListingColor {
  font-weight: 600;
  font-size: 0.9rem;
  opacity: 0.8;
  margin-bottom: 0;
  color:#ff8a2a;
}

.categoryListingName {
  font-weight: 600;
  font-size: 1.05rem;
  margin: 0;
}

.categoryListingPrice {
  margin-top: 0.5rem;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
  width: 40%;
  font-size: 1.1rem;
  color: #009c49f8;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.categoryListingInfoDiv {
  display: flex;
  justify-content: space-between;
  max-width: 275px;
}

.categoryListingInfoText {
  font-weight: 500;
  font-size: 0.7rem;
}

.loadMore {
  cursor: pointer;
  width: 8rem;
  margin: 0 auto;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  opacity: 0.7;
  margin-top: 0;
}

.formatted-text {
  white-space: pre-wrap;
  line-height: 1.5; /* Adjust the line height as needed */
}

.listingDetails {
  position: absolute;
  margin-top: 0px;
  margin-bottom: 10rem;
  background-color: #ffffff;
  padding: 15px;
  width: 95%;
}

.topside-component {
  width: 200px;
}

.shareIconDiv {
  cursor: pointer;
  position: fixed;
  top: 3%;
  right: 5%;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listingName {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.listingLocation {
  margin-top: 0;
  font-weight: 600;
}

.discountPrice {
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  display: inline;
}

.listingType {
  padding: 0.25rem 0.5rem;
  background-color: #fff700;
  color: #181818;
  border-radius: 2rem;
  display: inline;
  font-weight: 600;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.listingDetailsList {
  padding: 0;
  list-style-type: none;
}
.listingDetailsList li {
  margin: 0.3rem 0;
  font-weight: 500;
  opacity: 0.8;
}

.listingLocationTitle {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.leafletContainer {
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .leafletContainer {
    height: 400px;
  }
}

@media (min-width: 1920px) {
  .pageContainer {
    padding-top: 50px;
    width:90%;
    display: flex;
    flex-direction: row;
    height: 500px;
    justify-content: center;
  }
}

.linkCopied {
  position: fixed;
  top: 9%;
  right: 5%;
  z-index: 2;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
}

.contactListingName {
  margin-top: -1rem;
  margin-bottom: 0;
  font-weight: 600;
}

.contactListingLocation {
  margin-top: 0.25rem;
  font-weight: 600;
}

.contactLandlord {
  margin-top: 2rem;
  display: flex;
  align-items: center;
}

.landlordName {
  font-weight: 600;
  font-size: 1.2rem;
}

.messageForm {
  margin-top: 0.5rem;
}

.messageDiv {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.messageLabel {
  margin-bottom: 0.5rem;
}

.profile {
  margin-bottom: 10rem;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoimg {
  width: 200px;
  height: auto;
}

.logoback {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  background-color: #F56B00;
  padding: 10px 0 5px 25px; 
}

.categoryListingDate {
  display: flex;
  align-items: center;
  color: #a3a3a3;
   font-family: 'Montserrat', sans-serif;
   font-weight: 400;
   font-size: 14px;

}

.time {
  padding-right: 5px;
  height: 100%;
  width: auto;
}

.signincontainer {
  display: flex;
  flex-direction: row;
}

.jobclass img {
  height:auto;
  width: 90%;
  z-index: -10;
}


.jobimg {
  height:10%;
  width: auto;
  margin-left: 100px;
}


.logOut {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #ff8a2a;
  padding: 1.25rem 1.75rem;
  border-radius: 1rem;
  width: 150px;
}

.kp {
  margin-top: 20px;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
}

.krijoprofilin {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #ff8a2a;
  padding: 1.25rem 1.75rem;
  border-radius: 1rem;
  width: 150px;
  /* margin-left: 20px; */
}


/* SignUp.css */

.radioContainer {
  text-align: center;
  margin-bottom: 80px;
}

.radioGroup {
  display: inline-block;
  text-align: left;
  margin-right: 20px;
}

.radioInput {
  margin-right: 5px;
  transform: scale(1.8); /* Increase size a little bit */
  accent-color: rgb(40, 40, 40);
  margin-right: 10px;
}


.profileDetailsHeader {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
}

.personalDetailsText {
  font-weight: 600;
}

.changePersonalDetails {
  cursor: pointer;
  font-weight: 600;
  color: #0d0d0d;
}

.profileCard {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2);
  max-width: 500px;
}

.profileDetails {
  display: flex;
  flex-direction: column;
}

.profileName,
.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive,
.profileNameActive {
  all: unset;
  margin: 0.3rem 0;
  font-weight: 600;
  width: 100%;
}
.profileNameActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.profileEmail,
.profileAddress,
.profileAddressActive,
.profileEmailActive {
  font-weight: 500;
}
.profileEmailActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.profileAddressActive {
  background-color: rgba(44, 44, 44, 0.1);
}

.createListing {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  font-weight: 600;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listingText {
  margin-top: 3rem;
  font-weight: 600;
}

.lisitingsList {
  padding: 0;
}

.formLabel {
  font-weight: 600;
  margin-top: 1rem;
  display: block;
}

.formButtons {
  display: flex;
}



.formButton,
.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile,
.formButtonActive {
  padding: 0.9rem 3rem;
  background-color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}


.formInputFile {
  width: 98%;
  max-width: 1366px;
}

.formInputNameDetyrat {
  padding: 0.9rem 3rem;
  background-color: #e8e8e8;
  font-weight: 600;
  border-radius: 1rem;
  font-size: 1rem;
  margin: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px
}

.formButtonActive {
  background-color: #fff700;
  color: #242424;
}

.flex {
  display: flex;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  width: 200%;
  border-radius: 15px;
}

.formInput,
.formInputAddress,
.formInputName,
.formInputSmall,
.formInputFile {
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* border-color: #bfbfbf;
  border-width: 1px; */
  outline: none;
  font-family: 'Montserrat', sans-serif;
}
.formInputSmall,
.formInputFile {
  margin-right: 3rem;
  padding: 0.9rem 0.7rem;
  text-align: center;
}

.formInputName {
  padding: 0.9rem 1rem;
  width: 98%;
  max-width: 1366px;
}

.formInputAddress {
  padding: 0.9rem 1rem;
  width: 90%;
  /* max-width: 326px; */
}

.formPriceDiv {
  display: flex;
  align-items: center;
}

.formPriceText {
  margin-left: -1.5rem;
  font-weight: 600;
}

.imagesInfo {
  font-size: 0.9rem;
  opacity: 0.75;
}

.formInputFile {
  width: 100%;
}
.formInputFile::-webkit-file-upload-button {
  background-color: #ff8a2a;
  border: none;
  color: #131313;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.createListingButton {
  margin-top: 5rem;
}

.offers {
  margin-bottom: 10rem;
}

.offerBadge {
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #1b1b1b;
  border-radius: 1rem;
  margin-left: 1rem;
  font-size: 0.8rem;
  opacity: 0.75;
}

@media (max-width: 550px) {
  
  .cmimiPremium {
    font-size: 30px;
    color: #ff8a2a;
    /* margin-left: -70px;
    margin-bottom: -10px; */
  }
  
  .cmimiMuaji {
    color: #b2b2b2;
    font-size: 14px;
    /* margin-left: -30px; */
  }

  .cmimi {
    display: flex;
    flex-direction: row
  }
  

}

