.pays {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pays p {
    font-size: 55px;
    color: dodgerblue;
}

.payseraimg {
    height: 65%;
    width:auto;
}

.paysera {
    margin-top: -5px;
}


.payment-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .payment-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .payment-option {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .payment-option label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    color: #555;
  }
  
  .payment-option input {
    margin-right: 10px;
  }
  
  .payment-option span {
    transition: color 0.3s;
  }
  
  .payment-option input:checked + span {
    color: #007bff;
  }
  
  .pay-button {
    width: 100%;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pay-button:hover {
    background-color: #0056b3;
  }