.linkuprivatesise {
display:flex;
flex-direction: row;
justify-content: center;
}

.privatesia {
    margin-right: 20px;
}


@media (max-width: 540px) {
 
    .linkuprivatesise {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -150px;
        margin-bottom: 100px;
     }

     .privatesia {
        margin-bottom: 25px;
    }
        
}